export const Day_open = {
  Panel: "ວາລະ​ງານ​ສັບ​ປະ​ດາດິ​ຈິ​ຕອນ ປະຈໍາປີ2024",
  day1: "ວັນທີ 10 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06",
  day2: "ວັນທີ 11 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06",
  day3: "ວັນທີ 12 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06",
  day4: "ວັນທີ 13 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06",
  day5: "ວັນທີ 14 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06",
};

export const Panel = [
  {
    id: 1,
    time: "08:00 ~ 08:30",
    intro: "ລົງທະບຽນ",
    title: "ຜູ້ເຂົ້າຮວ່ມ",
  },
  {
    id: 2,
    time: "10:00 ~ 11:00",
    intro: "ກ່າວຕ້ອນຮັບແຂກ",
    title: "ພິທີກອນ",
  },
  {
    id: 3,
    time: "08:35 ~ 08:45",
    intro: "ກ່າວຈຸດປະສົງຂອງງານ",
    title: "ຫົວໜ້າຄະນະຈັດງານ",
  },
  {
    id: 4,
    time: "08:45 ~ 08:50",
    intro: "ແນະນຳຄະນະກຳມະການແລະທິມເຂົ້າຮ່ວມການແຂ່ງຂັນ",
    title: "ພິທີກອນ",
  },
  {
    id: 5,
    time: "08:45 ~ 08:50",
    intro: "ເເນະນຳລະບຽບການແຂ່ງຂັນແລະລາງວັນຜູ້ຊະນະເລີດ",
    title: "ຄະນະຈັດງານ",
  },
  {
    id: 6,
    time: "08:45 ~ 08:50",
    intro: "ກ່າວຂອບໃຈ ຜູ້ສະໜັບສະໜູນ",
    title: "ພິທີກອນ",
  },
  {
    id: 7,
    time: "08:45 ~ 08:50",
    intro: "ການແຂ່ງຂັນ Lao ICT Awards 2022",
    title: "ຜູ້ເຂົ້າແຂ່ງຂັນ",
  },
  {
    id: 8,
    time: "08:45 ~ 08:50",
    intro: "ພັກຜ່ອນ/ກິດຈະກຳ",
    title: "ໂຄສະນາຜູ້ສະໜັບສະໜູນ",
  },
  {
    id: 9,
    time: "08:45 ~ 08:50",
    intro: "ສຶບຕໍ່ການແຂ່ງຂັນ Lao ICT Awards 2022",
    title: "ຜູ້ເຂົ້າແຂ່ງຂັນ",
  },
];

export const SpeakerDay1 = [
  {
    id: 1,
    name: "speaker1",
    detail: "detail",
    image: "",
  },
  {
    id: 2,
    name: "speaker2",
    detail: "detail",
    image: "",
  },
  {
    id: 3,
    name: "speaker3",
    detail: "detail",
    image: "",
  },
  {
    id: 4,
    name: "speaker4",
    detail: "detail",
    image: "",
  },
];
