import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Tabs from "../../components/Tabs";
import { FaPaperPlane } from "react-icons/fa";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { HiEnvelope } from "react-icons/hi2";
import { MdQrCode } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { USERKEY } from "../../consts";
import { Banner } from "../../api";
// import QRCode from 'qrcode';
// import QRCode from "qrcode.react";
import ReactLoading from "react-loading";
import moment from "moment";

export default function Home() {
  const navigate = useNavigate();
  const [banner, setBanner] = useState();
  const [userData, setUserData] = useState("");
  // const [userkey, setUserKey] = useState("");
  const [ishowUser, setIshowUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showtime, setShowTime] = useState(true);

  const [timerDays, setTimedays] = useState("00");
  const [timerHours, setTimeHours] = useState("00");
  const [timerMinutes, setTimeMinutes] = useState("00");
  const [timerSeconds, setTimeSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    setLoading(true);
    const countdownDate = new Date("January 10,2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance <= 0) {
        clearInterval(interval.current);
        setShowTime(false);

        //    stop our time
      } else {
        // update timer
        setTimedays(days);
        setTimeHours(hours);
        setTimeMinutes(minutes);
        setTimeSeconds(seconds);
      }
    }, 1000);
    setLoading(false);
  };

  // componentDisMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  // get UserData

  useEffect(() => {
    const user = localStorage.getItem(USERKEY);

    if (user && moment(user?.user?.createdAt).format("yyyy") === "2024") {
      setIshowUser(true);
      setUserData(JSON.parse(user));
      // setUserKey(JSON.parse(user).user.userKey)
    }
    if (!user) setIshowUser(false);
  }, []);

  // get Banner

  useEffect(() => {
    setLoading(true);

    axios.get(Banner).then((response) => {
      setBanner(response.data[0]);
      setLoading(false);
    });
  }, []);

  if (!Banner) return setLoading(true);

  // console.log("bannerimage==>", banner)

  // console.log("userdata=====>", userData)
  // console.log("userKey=====>", userkey)

  return (
    <>
      {loading ? (
        <div className="Loadding">
          <ReactLoading type="spin" color="#2D9CDB" width={50} height={50} />
        </div>
      ) : (
        <div className="home-main">
          <div className="box-main-wrapper">
            <div className="box-main"></div>
            <div className="image-banner-wrapper">
              {/* {banner} */}
              <img src={banner?.image} className="image-banner" alt="" />
            </div>
          </div>

          <div className="home-content">
            <div style={{ height: "10px" }}></div>
            {ishowUser ? (
              <>
                <div className="Box-user-login">
                  <span style={{ fontWeight: "400", color: "#133345" }}>
                    <BsFillCheckCircleFill color="#34D399" size={16} />
                    &ensp;{userData?.user?.name}
                  </span>
                  <span style={{ fontWeight: "700", color: "#10B981" }}>
                    ລົງທະບຽນສຳເລັດ
                  </span>
                </div>{" "}
              </>
            ) : (
              <></>
            )}

            {showtime ? (
              <>
                {" "}
                <div className="time-head">ມື້ນັບຖອຍຫຼັງເຂົ້າງານ</div>
                <div className="countdown">
                  <div className="time-item">
                    <span className="time">
                      {String(timerDays).padStart(2, "0")}
                    </span>
                    <span>ມື້</span>
                  </div>
                  <span className="time">:</span>
                  <div className="time-item">
                    <span className="time">
                      {String(timerHours).padStart(2, "0")}
                    </span>
                    <span>ຊົ່ວໂມງ</span>
                  </div>
                  <span className="time">:</span>
                  <div className="time-item">
                    <span className="time">
                      {String(timerMinutes).padStart(2, "0")}
                    </span>
                    <span>ນາທີ</span>
                  </div>
                  <span className="time">:</span>
                  <div className="time-item">
                    <span className="time">
                      {String(timerSeconds).padStart(2, "0")}
                    </span>
                    <span>ວິນາທີ</span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="tabbar-custom">
              <Tabs />
              <div
                className="contact"
                style={{
                  height: ishowUser ? "0" : "0",
                  backgroundColor: ishowUser ? "red" : "green",
                }}
              >
                <p>ກະຊວງເຕັກໂນໂລຊີ ແລະ ການສື່ສານ</p>
                <div className="contact-web-wrapper">
                  <a
                    href="https://digitalweek.gov.la/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <button className="contact-web">
                      <MdLanguage size={20} color="#507AB5" />
                      &ensp;digitalweek.gov.la
                    </button>
                  </a>
                  <a
                    href="https://www.facebook.com/Laodigitalweek"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <button className="contact-web">
                      <BsFacebook size={20} color="#507AB5" />
                      &ensp;Lao Digital Week
                    </button>
                  </a>
                </div>
                <div className="contact-about">
                  <div>
                    <p>ສອບຖາມຂໍ້ມູນເພີ່ມເຕີ່ມ</p>
                    <p
                      onClick={() => {
                        window.location.href = `tel:+856 021 215 877`;
                      }}
                    >
                      <FaPhoneAlt color="#507AB5" />
                      &ensp;+856 20 5548 6442
                    </p>
                  </div>
                  <div>
                    <p>ສອບຖາມຜ່ານອີເມວ</p>
                    <p>
                      <HiEnvelope color="#507AB5" />
                      &ensp;TPTC@mtc.gov.la
                    </p>
                  </div>
                </div>
                {ishowUser ? (
                  <>
                    <div
                      className="float"
                      style={{
                        zIndex: 99,
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/qrcode")}
                    >
                      <MdQrCode size={32} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    height: "60px",
                    backgroundColor: ishowUser ? "#197faf" : "#EDF0F3",
                    width: "100%",
                    color: ishowUser ? "white" : "#133345",
                    fontSize: "14px",
                    fontWeight: "400",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Powered by: Lailaolab ICT Solutions
                </div>
                {ishowUser ? "" : <div style={{ height: "40px" }}></div>}
              </div>
            </div>
          </div>

          {!ishowUser ? (
            <div className="form-main-home">
              <button className="footer" onClick={() => navigate("Register")}>
                <div>
                  ລົງທະບຽນ&ensp;
                  <FaPaperPlane />
                </div>
              </button>
            </div>
          ) : (
            ""
          )}
          {/* {ishowUser ? <><div className='sticky'>MY QR</div></> : <></>} */}
        </div>
      )}
    </>
  );
}
