import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { Agendaevents, BACKEND_URL, Banner } from "../../api";
import { USERKEY } from "../../consts";
import { successAdd } from "../../helper/sweetalert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faUserLarge } from "@fortawesome/free-solid-svg-icons";

export default function Detail() {
  const navigate = useNavigate();
  const [agenda, setAgenda] = useState([]);
  const [userData, setUserData] = useState("");
  const [userkey, setUserKey] = useState();
  // const [userjoin, setUserJoin] = useState();
  const [isjoin, setIsJoin] = useState(false);

  const [userid, setUserId] = useState("");
  const param = useParams();
  const [ishowbutton, setishowButton] = useState(false);
  // const [ishowjoin, setIshowJoin] = useState(false);
  const [banner, setBanner] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // console.log("param", param?.class);
    axios.get(Agendaevents).then((respone) => {
      const newData = respone?.data?.filter(
        (item) =>
          item?.day === parseInt(param?.day) && item?.class === param?.class
      );
      // console.log("new data: ", newData);
      setAgenda(newData);
    });
    // checkjoin(JSON.parse(user).user._id)
  }, [setAgenda]);

  useEffect(() => {
    axios.get(Banner).then((respone) => {
      setBanner(respone.data);
    });
  }, []);

  useEffect(() => {
    const user = localStorage.getItem(USERKEY);
    if (user) {
      setUserKey(JSON.parse(user).user.userKey);
      setishowButton(true);
      setUserData(JSON.parse(user));
      setUserId(JSON.parse(user).user._id);
    }
    if (!user) setishowButton(false);
  }, []);

  const checkjoin = () => {
    const _userkey = userkey;
    const room = param?.class;
    const day = param?.day;

    axios
      .get(`${BACKEND_URL}/joinhistory?room=${room}&day=${day}&userkey=${_userkey}`)
      .then((respone) => {
        // console.log("response: ", respone.data.joinhistory)
        // console.log("respone?.data?.joinhistory.length > 0: ", respone?.data?.joinhistory.length > 0)
        if (respone?.data?.joinhistory.length > 0) {
          setIsJoin(true);
        } else {
          setIsJoin(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (userkey) {
      checkjoin();
    }
  }, [userkey]);

  const handlejoin = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      day: Number(param?.day),
      room: param?.class,
      user: userData?.user,
      userKey: userkey,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${BACKEND_URL}/joinhistory`, requestOptions)
      .then((result) => {
        handleClose();
        checkjoin();
        successAdd("ເຂົ້າຮ່ວມສຳເລັດ");
      })
      .catch((error) => console.log("error", error));
  };

  // console.log("uersata===>", userData)
  return (
    <>
      <div className="home-main">
        <div className="box-main-wrapper">
          <div className="box-main"></div>
          <div className="image-banner-wrapper">
            {/* {banner} */}
            <img src={banner?.[0]?.image} className="image-banner" alt="" />
          </div>
        </div>

        <div className="home-content">
          <div className="tabbar-custom">
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "52px",
                padding: "0 12px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div onClick={() => navigate("/")}>
                <AiOutlineArrowLeft size={20} color="#2486BA" />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#133345",
                }}
              >
                {param?.title}
              </div>
              <div></div>
            </div>
            <hr
              style={{
                width: "100%",
                border: "2px solid #E0E0E0",
                margin: "0",
              }}
            ></hr>

            {isjoin ? (
              <>
                {" "}
                <center>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#10B981",
                      margin: 0,
                      paddingTop: "12px",
                      marginBottom: "-12px",
                    }}
                  >
                    ເຂົ້າຮ່ວມເເລ້ວ
                  </p>
                </center>
              </>
            ) : (
              <></>
            )}

            <center
              style={{
                marginBottom: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: "12px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#133345",
                  margin: 0,
                }}
              >
                ວາລະພາຍໃນງານ
              </p>
            </center>

            {agenda?.map((agendaeven, index) => (
              <div key={index} className="container-box-vala">
                <div className="box-time-vala">
                  <div
                    style={{
                      backgroundColor: "#2486BA33",
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      color: "#0E4065",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    {agendaeven.day === 1 &&
                      "ວັນທີ່ 10 ມັງກອນ 2024, ເວລາ " + agendaeven?.time}
                    {agendaeven.day === 2 &&
                      "ວັນທີ່ 11 ມັງກອນ 2024, ເວລາ " + agendaeven?.time}
                    {agendaeven.day === 3 &&
                      "ວັນທີ່ 12 ມັງກອນ 2024, ເວລາ " + agendaeven?.time}
                    {agendaeven.day === 4 &&
                      "ວັນທີ່ 13 ມັງກອນ 2024, ເວລາ " + agendaeven?.time}
                    {agendaeven.day === 5 &&
                      "ວັນທີ່ 14 ມັງກອນ 2024, ເວລາ " + agendaeven?.time}
                  </div>
                  <div
                    style={{
                      margin: "8px 0",
                    }}
                  >
                    {agendaeven?.title?.map((title, index) => (
                      <p
                        key={index}
                        style={{
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#133345",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            width={8}
                            color="##0E4065"
                            style={{
                              marginTop: ".2rem",
                            }}
                          />
                          {title}
                        </span>
                      </p>
                    ))}
                  </div>

                  {agendaeven?.chairman?.map((title, index) => (
                    <p
                      key={index}
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        margin: "6px 0",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          color: "#0E4065",
                          fontSize: "14px",
                        }}
                      >
                        <FontAwesomeIcon icon={faUserLarge} color="#9CA3AF" />
                        {title}
                      </span>
                      {/* <span
                        style={{
                          paddingLeft: "24px",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        ປະທານ
                      </span> */}
                    </p>
                  ))}

                  {agendaeven?.speaker?.map((title, index) => (
                    <p
                      key={index}
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        margin: "6px 0",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          color: "#0E4065",
                          fontSize: "14px",
                        }}
                      >
                        <FontAwesomeIcon icon={faUserLarge} color="#9CA3AF" />
                        {title}
                      </span>
                      {/* <span
                        style={{
                          paddingLeft: "24px",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        ຜູ້ບັນລະຍາຍ
                      </span> */}
                    </p>
                  ))}

                  <p
                    key={index}
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      margin: "6px 0",
                    }}
                  >
                    {agendaeven?.support.map((list, listIndex) => (
                      <span
                        key={`support-${listIndex}`}
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          color: "#0E4065",
                          fontSize: "14px",
                        }}
                      >
                        <FontAwesomeIcon icon={faUserLarge} color="#9CA3AF" />
                        {list}
                      </span>
                    ))}

                    {/* <span
                      style={{
                        paddingLeft: "24px",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      ຜູ້ຮັບຜິດຊອບ
                    </span> */}
                  </p>

                  {/* <p>
                    <IoMdPerson /> ຜູ້ຮັບຜິດຊອບ
                  </p>

                  <p
                    style={{
                      paddingLeft: "21px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {agendaeven?.support}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
          <div style={{ height: "100px" }}></div>

          {!ishowbutton ? (
            ""
          ) : (
            <>
              {" "}
              {isjoin && userid ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className="form-main-home">
                    <button className="footer" onClick={handleShow}>
                      <div>ເຂົ້າຮ່ວມ</div>
                    </button>
                  </div>{" "}
                </>
              )}
            </>
          )}

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "20px", fontWeight: "700" }}>
                  ຢືນຍັນການເຂົ້າຮ່ວມ
                </p>
                <p>ທ່ານຕ້ອງການເຂົ້າຮ່ວມ {param?.titles} ແທ້ ຫຼື ບໍ່?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                ຍົກເລີກ
              </Button>
              <Button variant="primary" onClick={() => handlejoin()}>
                ເຂົ້າຮ່ວມ
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
