import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { successAdd, warningAlert } from "../../helper/sweetalert";
import { USERKEY } from "../../consts";
import { json, redirect, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { BACKEND_URL } from "../../api";

export default function Register() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [from, setForm] = useState("");

  // setEmpty
  const [isNameEmpty, setNameisEmpty] = useState("");
  const [isphonenumberEmpty, setIsPhoneNumberEmpty] = useState("");
  const [isfromEmpty, setIsFromEmpty] = useState("");

  // loading
  const [Loadding, setLoadding] = useState(false);

  const handlesubmit = (even) => {
    even.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      phoneNumber: phoneNumber,
      name: name,
      from: from,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (name === "" && phoneNumber === "" && from === "") {
      setNameisEmpty("ກະລຸນາປ້ອນຊື່");
      setIsPhoneNumberEmpty("ກະລຸນາປ້ອນເບີໂທ");
      setIsFromEmpty("ກະລຸນາປ້ອນຂໍ້ມູນ");
    } else if (phoneNumber.length < 8) {
      setIsPhoneNumberEmpty("ກະລຸນາປ້ອນເບີໂທໃຫ້ຖືກຕ້ອງ 8-13 ຕົວເລກ");
    } else if (phoneNumber.length > 13) {
      setIsPhoneNumberEmpty("ກະລຸນາປ້ອນເບີໂທໃຫ້ຖືກຕ້ອງ 8-13 ຕົວເລກ");
    } else if (phoneNumber.length > 13) {
      setIsPhoneNumberEmpty("ກະລຸນາປ້ອນເບີໂທໃຫ້ຖືກຕ້ອງ 8-13 ຕົວເລກ");
    } else if (from.length < 1) {
      setIsFromEmpty("ກະລຸນາປ້ອນຂໍ້ມູນ");
    } else {
      setLoadding(true);
      fetch(`${BACKEND_URL}/user`, requestOptions)
        .then((response) => {
          if (response.status === 400) {
            warningAlert("ມີເບີໂທນີ້ໃນລະບົບເເລ້ວ");
            setLoadding(false);
            return null;
          }
          return response.json();
        })
        .then((result) => {
          if (result != null) {
            localStorage.setItem(USERKEY, JSON.stringify(result));
            successAdd("ລົງທະບຽນສຳເລັດ");
            navigate("/");
            setLoadding(false);
            // console.log("adta==>", result);
          }
          return null;
        })
        .catch((error) => console.log("error", error));
    }
  };

  // console.log("data====>",)

  const navigate = useNavigate();
  return (
    <>
      {Loadding ? (
        <div className="Loadding">
          <ReactLoading type="spin" color="#2D9CDB" width={50} height={50} />
        </div>
      ) : (
        <form onSubmit={handlesubmit}>
          <div className="main">
            {/* menu */}

            <div className="top">
              <div className="item">
                <div onClick={() => navigate("/")}>
                  <FaArrowLeft color="#2486BA" />
                </div>
                <div>
                  <img src="/assets/image/logo.png" width={40} alt="" />
                </div>
              </div>
            </div>

            {/* banner */}

            <div className="box-main-wrapper">
              <div className="box-main"></div>
              <div className="image-banner-wrapper">
                {/* {banner} */}
                <img
                  src="https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/assets/image/banner.jpg"
                  className="image-banner"
                  alt=""
                />
              </div>
            </div>

            {/* title */}

            <div className="title-form">
              <div className="title-top"></div>
              <div className="content-title">
                <b>ແບບຟອມລົງທະບຽນງານສັບປະດາດິຈິຕ໋ອນ</b>
                <p>ວັນທີ 10 ມັງກອນ 2024, ທີ່ຫໍປະຊຸມແຫ່ງຊາດຫຼັກ 06</p>
              </div>
            </div>

            {/* input-register */}

            <div className="form-main">
              <div className="input-box">
                <input
                  type="text"
                  placeholder="ຊື່ ແລະ ນາມສະກຸນ..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <p style={{ color: "red" }}>{isNameEmpty}</p>
                <div style={{ marginTop: "24px" }}></div>
                <input
                  type="number"
                  placeholder="ເບີໂທ..."
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  minLength={8}
                />
                <p style={{ color: "red" }}>{isphonenumberEmpty}</p>
                <div style={{ marginTop: "24px" }}></div>

                <input
                  type="text"
                  placeholder="ມາຈາກ (ກະຊວງ, ບໍລິສັດ, ອົງກອນ)..."
                  onChange={(e) => setForm(e.target.value)}
                />
                <p style={{ color: "red" }}>{isfromEmpty}</p>
              </div>
              <div className="logo-form">
                <img src="/assets/image/logo.png" width={220} alt="" />
              </div>
              <button type="submit" className="footer">
                <div>
                  ລົງທະບຽນ&ensp;
                  <FaPaperPlane />
                </div>
              </button>
              {/* <div className='footer' onClick={() => navigate('home')} ><div>ລົງທະບຽນ&ensp;<FaPaperPlane /></div></div> */}
            </div>
          </div>
        </form>
      )}
    </>
  );
}
