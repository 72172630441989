// export const Sponcors =
//   "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/sponsors.json";
// export const Speakers =
//   "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/speakers.json";
export const Eventdetail =
  "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/event-detail.json";
export const Eventcreater =
  "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/event-creater.json";
// export const Agendaevents =
//   "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/agenda-events.json";
export const Banner =
  "https://s3.ap-southeast-1.amazonaws.com/digital-week.lailaolab.la/data/banner.json";
export const enjoin = "";

export const Sponcors = "/data/sponsors.json";
export const Speakers = "/data/speakers.json";
export const Agendaevents = "/data/agenda-events.json";

export const BACKEND_URL = "https://digital-week-api.lailaolab.com/dev";
// export const BACKEND_URL = "http://localhost:9090";
