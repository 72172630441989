import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
// import { FaUserAlt } from "react-icons/fa";
import Day from "./Day";

import { Day_open } from "../consts/Information";
// import { SpeakerDay1 } from "../consts/Information";
// import { Panel } from "../consts/Information";
import { Sponcors, Speakers, BACKEND_URL } from "../api";
import { useNavigate, useParams } from "react-router-dom";
// import { Room } from "../consts/room";
import AgendaAll from "./AgendaAll";
import { USERKEY } from "../consts";

export default function Tabs() {
  const data = [
    {
      id: 1,
      title: "ຫ້ອງປະຊຸມໃຫຍ່ N1",
      zone: "(ໂຊນ DG)",
      class: "N1",
    },
    {
      id: 2,
      title: "ຫ້ອງ N14",
      zone: "(ໂຊນ DG)",
      class: "N14",
    },
    {
      id: 3,
      title: "ຫ້ອງ N4",
      zone: "(ໂຊນ DS)",
      class: "N4",
    },
    {
      id: 4,
      title: "ເວທີກາງ (ຫ້ອງໂຖງ)",
      zone: "(ໂຊນ DE)",
      class: "N5",
    },
  ];

  const classList = [
    {
      id: 1,
      title: "ເວທີກາງ (ຫ້ອງໂຖງ)",
      class: "N5",
    },
    {
      id: 2,
      title: "ຫ້ອງປະຊຸມໃຫ່ຍ N1",
      class: "N1",
    },
    {
      id: 3,
      title: "ຫ້ອງ N2",
      class: "N2",
    },
    {
      id: 4,
      title: "ຫ້ອງ N3",
      class: "N3",
    },
    {
      id: 5,
      title: "ຫ້ອງ N4",
      class: "N4",
    },
    {
      id: 6,
      title: "ຫ້ອງ N14",
      class: "N14",
    },
    {
      id: 7,
      title: "ຫ້ອງ N15",
      class: "N15",
    },
    {
      id: 8,
      title: "ຫ້ອງ N101",
      class: "N101",
    },
    {
      id: 9,
      title: "ຫ້ອງ N12",
      class: "N12",
    },
  ];

  const navigate = useNavigate();
  const [basicActive, setBasicActive] = useState(
    localStorage.getItem("day") ?? "tab1"
  );
  // const [evendetail, setEvenDetail] = useState();
  const [sponsor, setSponSor] = useState();
  // const [agenda, setAgenda] = useState();
  const [speaker, setSpeaker] = useState();

  const [day, setDay] = useState(localStorage.getItem("days") ?? "1");

  const param = useParams();
  const [userData, setUserData] = useState("");
  const [userkey, setUserKey] = useState();
  const [isjoin, setIsJoin] = useState([]);
  const [userid, setUserId] = useState("");
  const [ishowbutton, setishowButton] = useState(false);

  const [show, setShow] = useState(false);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
    localStorage.setItem("day", value);
    if (value === "tab6") setDay(6);
    if (value === "tab1") setDay(1);
    if (value === "tab2") setDay(2);
    if (value === "tab3") setDay(3);
    if (value === "tab4") setDay(4);
    if (value === "tab5") setDay(5);

    if (value === "tab1") localStorage.setItem("days", 1);
    if (value === "tab2") localStorage.setItem("days", 2);
    if (value === "tab3") localStorage.setItem("days", 3);
    if (value === "tab4") localStorage.setItem("days", 4);
    if (value === "tab5") localStorage.setItem("days", 5);
  };

  useEffect(() => {
    axios.get(Sponcors).then((response) => {
      setSponSor(response.data);
    });
  }, []);

  useEffect(() => {
    axios.get(Speakers).then((response) => {
      setSpeaker(response.data);
    });

    // console.log("Speacker", speaker);
  }, []);

  // useEffect(() => {
  //     axios.get(Agendaevents).then((response) => {
  //         setAgenda(response.data);
  //     });
  // }, []);

  useEffect(() => {
    const user = localStorage.getItem(USERKEY);
    if (user) {
      setishowButton(true);
      setUserData(JSON.parse(user));
      setUserId(JSON.parse(user).user._id);
      setUserKey(JSON.parse(user).user.userKey);
    }
    if (!user) setishowButton(false);
  }, []);

  useEffect(() => {
    const checkjoin = () => {
      const _userkey = userkey;
      // setLoading(true)
      // const room = param?.class;
      // const day = param?.day;

      axios
        .get(`${BACKEND_URL}/joinhistory?userkey=${_userkey}`)
        .then((respone) => {
          console.log("_userkey: ", _userkey);
          console.log(
            "response.data.joinhistory: ",
            respone?.data?.joinhistory
          );
          setIsJoin(respone?.data?.joinhistory);
          // return respone?.data?.joinhistory.filter(
          //   (e) => e?.user?.userKey === _userkey
          // );
        })
        // .then((joined) => {
        //   console.log("joined---->>>",joined)
        //   setIsJoin(joined);
        // })
        .catch((error) => console.log("error", error));
    };

    if (userkey) {
      checkjoin();
    }
  }, [userkey]);

  if (!sponsor) return "";

  return (
    <div>
      {/* Tabs */}

      <MDBTabs
        className="mb-3"
        style={{
          fontSize: "20px",
          borderBottom: "1px solid #E0E0E0",
          width: "100%",
        }}
      >
        <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab6")}
            active={basicActive === "tab6"}
            style={{
              color: basicActive === "tab6" ? "#2D9CDB" : "#133345",
              backgroundColor:
                basicActive === `tab6` ? "#2486BA14" : "transparent",
              fontSize: "14px",
            }}
          >
            <p>ທັງໝົດ</p>
          </MDBTabsLink>
        </MDBTabsItem>

        {[...Array(5)].map((array, index) => {
          return (
            <MDBTabsItem key={index} className="tabsItem">
              <MDBTabsLink
                className="tabsLink"
                onClick={() => handleBasicClick(`tab${index + 1}`)}
                active={basicActive === `tab${index + 1}`}
                style={{
                  color:
                    basicActive === `tab${index + 1}` ? "#2D9CDB" : "#133345",
                  backgroundColor:
                    basicActive === `tab${index + 1}`
                      ? "#2486BA14"
                      : "transparent",
                  fontSize: "14px",
                }}
              >
                <p>{`ມື້ທີ${index + 1}`}</p>
              </MDBTabsLink>
            </MDBTabsItem>
          );
        })}

        {/* <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab1")}
            active={basicActive === "tab1"}
            style={{
              color: basicActive === "tab1" ? "#2D9CDB" : "#133345",
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          >
            <p>ມື້ທີ1</p>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab2")}
            active={basicActive === "tab2"}
            style={{
              color: basicActive === "tab2" ? "#2D9CDB" : "#133345",
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          >
            <p>ມື້ທີ2</p>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab3")}
            active={basicActive === "tab3"}
            style={{
              color: basicActive === "tab3" ? "#2D9CDB" : "#133345",
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          >
            <p>ມື້ທີ3</p>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab4")}
            active={basicActive === "tab4"}
            style={{
              color: basicActive === "tab4" ? "#2D9CDB" : "#133345",
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          >
            <p>ມື້ທີ4</p>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem className="tabsItem">
          <MDBTabsLink
            className="tabsLink"
            onClick={() => handleBasicClick("tab5")}
            active={basicActive === "tab5"}
            style={{
              color: basicActive === "tab5" ? "#2D9CDB" : "#133345",
              backgroundColor: "transparent",
              fontSize: "14px",
            }}
          >
            <p>ມື້ທີ5</p>
          </MDBTabsLink>
        </MDBTabsItem> */}
      </MDBTabs>

      {/* Tab View */}

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === "tab6"}>
          <AgendaAll />
        </MDBTabsPane>

        {[...Array(5)].map((array, index) => {
          return (
            <MDBTabsPane key={index} show={basicActive === `tab${index + 1}`}>
              <Day
                title={Day_open[`day${index + 1}`]}
                detail={Day_open?.Panel}
                speaker={speaker?.map((speaker, index) => (
                  <React.Fragment key={index}>
                    {speaker[`day${index + 1}`]?.map((day, index) => (
                      <div key={index} className="speaker-wrapper">
                        <div>
                          <img src={day?.image} alt="" />
                        </div>

                        <span>
                          <b>{day?.name}</b>
                          <p>{day?.position}</p>
                          <p
                            style={{
                              marginTop: "-4px",
                            }}
                          >
                            {day?.company}
                          </p>
                        </span>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
                panelwork={
                  <>
                    <div className="grid-container">
                      {classList?.map((room) => (
                        <div
                          key={room.id}
                          className="grid-item"
                          onClick={() => {
                            // navigate("/detail", {
                            //     state: {
                            //         room: room,
                            //         day: day
                            //     }
                            // });
                            navigate(
                              `/detail/day/${day}/title/${room?.title}/zone/${room?.zone}/class/${room?.class}`
                            );
                          }}
                        >
                          <p>{room.title}</p>
                          <p>{room.zone}</p>

                          {isjoin?.map((joined) => {
                            if (
                              joined.day == day &&
                              joined.room == room?.class &&
                              userkey
                            ) {
                              // console.log(joined);
                              return (
                                <center key={index}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "700",
                                      color: "#10B981",
                                      margin: "-16px",
                                    }}
                                  >
                                    ເຂົ້າຮ່ວມເເລ້ວ
                                  </p>
                                </center>
                              );
                            }
                          })}
                        </div>
                      ))}
                    </div>
                  </>
                }
                kamkan={speaker?.map((k) => (
                  <>
                    {k?.kamkan?.map((kamkan, index) => (
                      <div key={index} className="speaker-wrapper">
                        <div>
                          <img src={kamkan?.image} alt="" />
                        </div>

                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <b>{kamkan?.name}</b>
                          <b>{kamkan?.en}</b>
                          <p>{kamkan?.position}</p>
                          <p
                            style={{
                              marginTop: "-4px",
                            }}
                          >
                            {kamkan?.form}
                          </p>
                        </span>
                      </div>
                    ))}
                  </>
                ))}
              />
            </MDBTabsPane>
          );
        })}
      </MDBTabsContent>
    </div>
  );
}
