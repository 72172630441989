import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserAlt } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { Eventdetail, Sponcors } from "../api";
import { useNavigate } from "react-router-dom";
import { height } from "@mui/system";
import { USERKEY } from "../consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function Day({ title, speaker, detail, panelwork, kamkan }) {
  const navigate = useNavigate();
  const [sponsor, setSponSor] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [imageData, setImageData] = useState("");
  const [showImageView, setImageView] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const _handleImageViewShow = () => setImageView(true);

  const [showcompany, setShowcompany] = useState(true);

  const _showImage = (value) => {
    _handleImageViewShow();
    setImageData(value);
  };

  useEffect(() => {
    axios.get(Sponcors).then((response) => {
      if (response) {
        setSponSor(response.data);
      }
    });
  }, []);

  if (!sponsor) return "";

  const handleLightbox = () => {
    setIsLightboxOpen(true);
    // console.log(isLightboxOpen);
  };

  return (
    <>
      {isLightboxOpen && (
        <div className="lightbox">
          <FontAwesomeIcon
            icon={faXmark}
            color="#fff"
            style={{
              fontSize: "24px",
              cursor: "pointer",
              position: "fixed",
              top: 20,
              right: 20,
              zIndex: 110,
            }}
            onClick={() => setIsLightboxOpen(false)}
          />
          <TransformWrapper>
            <TransformComponent>
              <img
                src="../../assets/image/map.png"
                className="image-banner"
                alt="test"
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}

      <center
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#0E4065",
          marginTop: "40px",
        }}
      >
        {detail}
      </center>
      <center style={{ fontSize: "12px", color: "#0E4065" }}>{title}</center>

      <div
        style={{
          paddingTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>{panelwork}</div>
      </div>

      <div className="speaker">
        <p style={{ fontWeight: "700" }}>ຜູ້ບັນລະຍາຍ</p>
        <div className="grid-flow">{speaker}</div>
      </div>

      <div className="speaker">
        <p style={{ fontWeight: "700" }}>ກຳມະການຕັດສິນ</p>
        <div className="grid-flow">{kamkan}</div>
      </div>

      <div className="speaker">
        <p style={{ fontWeight: "700", fontSize: "18px", color: "#133345" }}>
          ແຜນຜັງງານ
        </p>
        <div onClick={handleLightbox} style={{ padding: "20px" }}>
          <img
            src="../../assets/image/map.png"
            className="image-banner"
            alt="test"
          />
        </div>
      </div>

      <div style={{}}>
        {/* company */}
        <p
          style={{
            fontWeight: "700",
            fontSize: "18px",
            color: "#133345",
            paddingLeft: "10px",
          }}
        >
          ບໍລິສັດຮ່ວມວາງສະແດງ
        </p>
        {showcompany ? (
          <>
            {" "}
            <div
              className="sponsor-company"
              style={{ border: "1px solid #F3F4F6", height: "380px" }}
            >
              <div style={{ paddingTop: "5px", display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ຊື່ບໍລິສັດ
                </div>
                <div
                  style={{
                    width: "20%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ເລກບູສ
                </div>
                <div
                  style={{
                    width: "30%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ເບີໂທຕິດຕໍ່
                </div>
              </div>
              <hr />
              {sponsor?.map((cn, index) => (
                <div key={`sponsor-${cn}-${index}`}>
                  {cn?.company?.map((company, index) => (
                    <div
                      style={{ paddingTop: "10px", display: "flex" }}
                      key={`cn.company-${index}`}
                    >
                      <div
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#0E4065",
                          marginRight: "4px",
                        }}
                      >
                        {company?.name}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#2486BA",
                        }}
                      >
                        {company?.no}
                      </div>
                      <div
                        style={{
                          width: "30%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#3B82F6",
                        }}
                      >
                        {company?.phone}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "700",
                color: "#2D9CDB",
                marginTop: "20px",
              }}
              onClick={() => setShowcompany(!showcompany)}
            >
              <p>
                ສະແດງທັງໝົດ
                <FaAngleDown />
              </p>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              className="sponsor-company"
              style={{ border: "1px solid #F3F4F6" }}
            >
              <div style={{ paddingTop: "5px", display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ຊື່ບໍລິສັດ
                </div>
                <div
                  style={{
                    width: "20%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ເລກບູສ
                </div>
                <div
                  style={{
                    width: "30%",
                    fontWeight: "700",
                    color: "#6B7280",
                    fontSize: "14px",
                  }}
                >
                  ເບີໂທຕິດຕໍ່
                </div>
              </div>
              <hr />
              {sponsor?.map((cn, index) => (
                <div key={`sponsor-map-${index}`}>
                  {cn?.company?.map((company, index) => (
                    <div
                      key={`company-${company.name}-${index}`}
                      style={{ paddingTop: "10px", display: "flex" }}
                    >
                      <div
                        style={{
                          width: "50%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#0E4065",
                          marginRight: "4px",
                        }}
                      >
                        {company?.name}
                      </div>
                      <div
                        style={{
                          width: "20%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#2486BA",
                        }}
                      >
                        {company?.no}
                      </div>
                      <div
                        style={{
                          width: "30%",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#3B82F6",
                        }}
                      >
                        {company?.phone}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "700",
                color: "#2D9CDB",
                marginTop: "20px",
              }}
              onClick={() => setShowcompany(!showcompany)}
            >
              <p>
                ສະແດງໜ້ອຍລົງ
                <FaAngleUp />
              </p>
            </div>
          </>
        )}

        <div className="sponsor">
          <p>ສະປອນເຊີ້ທັງໝົດ</p>

          {/* Diamond */}

          <div className="sponsor-diamond">
            {sponsor[0].Diamond.length !== 0 && (
              <small>
                ສະປອນເຊີ້ລະດັບ&ensp; <button>{"Diamond"}</button>
              </small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.Diamond?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-diamond-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Platinum */}

          <div className="sponsor-platinum">
            {sponsor[0].Platinum.length !== 0 && (
              <small>
                ສະປອນເຊີ້ລະດັບ&ensp; <button>{"Platinum"}</button>
              </small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.Platinum?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-platinum-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Gold */}

          <div className="sponsor-gold">
            {sponsor[0].Gold.length !== 0 && (
              <small>
                ສະປອນເຊີ້ລະດັບ&ensp; <button>{"Gold"}</button>
              </small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.Gold?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-gold-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Silver */}

          <div className="sponsor-silver">
            {sponsor[0].Silver.length !== 0 && (
              <small>
                ສະປອນເຊີ້ລະດັບ&ensp; <button>{"Silver"}</button>
              </small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.Silver?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-silver-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Common */}

          <div className="sponsor-common">
            {sponsor[0].Common.length !== 0 && (
              <small>
                ສະປອນເຊີ້ລະດັບ&ensp; <button>{"Common"}</button>
              </small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.Common?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-common-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Media */}

          <div className="sponsor-common">
            {sponsor[0]?.media.length !== 0 && (
              <small>ຜູ້ສະໜັບສະໜຸນສື່ໂຄສະນາ</small>
            )}
            <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
              {sponsor[0]?.media?.map((sponsor, index) => {
                return (
                  <div
                    key={`sponsor-common-${index}`}
                    className="sponsor-wrapper"
                  >
                    <div className="sponsor-border">
                      <img src={sponsor?.image} alt="" />
                    </div>

                    <p>{sponsor?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* boot */}

        {/* <div className="sponsor" style={{ marginTop: "50px" }}>
          <p style={{ fontWeight: "700", fontSize: "18px", color: "#133345" }}>
            ຜູ້ສະໜັບສະໜຸນສື່ໂຄສະນາ
          </p>
          <div className="grid-flow-sponsors" style={{ paddingTop: "20px" }}>
            {sponsor?.map((M) => (
              <>
                {M?.media?.map((media) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #EBEEF5",
                        width: "110px",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={media?.image}
                        style={{
                          width: "100%",
                          padding: "10px",
                          objectfit: "cover",
                        }}
                        alt=""
                      />
                    </div>

                    <p style={{ fontSize: "14px", width: "auto" }}>
                      {media?.name}
                    </p>
                  </div>
                ))}
              </>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
}
