import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../api";
import axios from "axios";
import QRCode from "qrcode.react";
import { USERKEY } from "../../consts";
import { BACKEND_URL } from "../../api";
import { successAdd, warningAlert } from "../../helper/sweetalert";
import moment from "moment";
export default function Qrcode() {
  const [banner, setBanner] = useState();
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [userkey, setUserKey] = useState("");
  const [ishowUser, setIshowUser] = useState(true);
  const [userRegisterDay, setUserRegisterDay] = useState();

  useEffect(() => {
    axios.get(Banner).then((respone) => {
      setBanner(respone.data);
    });
  }, []);

  useEffect(() => {
    const user = localStorage.getItem(USERKEY);
    if (user) {
      setIshowUser(true);
      setUserData(JSON.parse(user));
      setUserKey(JSON.parse(user).user.userKey);
      getUserData(JSON.parse(user).user.userKey);
    }
    if (!user) setIshowUser(false);
  }, []);

  const getUserData = async (id) => {
    await axios.get(`${BACKEND_URL}/user-room/${id}`).then((respone) => {
      // console.log("respone", respone);
      setUserRegisterDay(respone?.data);
    });
  };

  const handlesubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      phoneNumber: userData?.user?.phoneNumber,
      userKey: userData?.user?.userKey ?? "",
      name: userData?.user?.name,
      from: userData?.user?.from,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BACKEND_URL}/user`, requestOptions)
      .then((response) => {
        // console.log("response", response);
        if (response.status === 400) {
          response.json().then((data) => {
            // console.log(data.message);
            // console.log(data.detail);
            if (data.message === "ALREADY_REGISTER") {
              successAdd("ທ່ານໄດ້ລົງທະບຽນເຂົ້າງານໃນມື້ນີ້ແລ້ວ");
            } else {
              warningAlert("ມີເບີໂທນີ້ໃນລະບົບເເລ້ວ");
            }
          });
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result != null) {
          localStorage.setItem(USERKEY, JSON.stringify(result));
          successAdd("ລົງທະບຽນສຳເລັດ");
          navigate("/");
        }
        return null;
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="home-main">
        <div className="box-main-wrapper">
          <div className="box-main"></div>
          <div className="image-banner-wrapper">
            {/* {banner} */}
            <img
              src={"/assets/image/banner.jpg"}
              className="image-banner"
              alt=""
            />
          </div>
        </div>

        <div className="home-content">
          <div className="tabbar-custom">
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "52px",
                padding: "0 12px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div onClick={() => navigate("/")}>
                <AiOutlineArrowLeft
                  size={20}
                  style={{
                    cursor: "pointer",
                  }}
                  color="#2486BA"
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#133345",
                }}
              >
                QR Code ຂອງຂ້ອຍ
              </div>
              <div></div>
            </div>
            <hr
              style={{
                width: "100%",
                border: "2px solid #E0E0E0",
                margin: "0",
              }}
            ></hr>

            <div className="qrcode-container">
              {ishowUser ? (
                <>
                  <div className="box-qrcode">
                    <b
                      style={{
                        color: "#133345",
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      QR Code ເຂົ້າຮ່ວມຫ້ອງ
                    </b>
                    <p
                      style={{
                        color: "#6B7280",
                        fontWeight: "400",
                        fontSize: "14px",
                        margin: "6px 0",
                      }}
                    >
                      ທ່ານສາມາດ ນຳເອົາ QR Code ຂອງທ່ານລົງທະບຽນເຂົ້າຮ່ວມຫ້ອງໄດ້
                    </p>
                    <div
                      style={{
                        marginTop: "24px",
                      }}
                    >
                      <QRCode value={userkey} size={260} />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                margin: "20px",
                flexWrap: "wrap",
              }}
            >
              ມື້ທີ່ລົງທະບຽນແລ້ວ:{" "}
              {/* {userRegisterDay?.map((data) => (
                <span>ວັນທີ່ {moment(data?.createdAt).format("DD")}, </span>
              ))} */}
              {userRegisterDay?.map((data) => (
                <span>
                  {moment(data?.createdAt).format("DD") === "10"
                    ? "ມື້ທີ່ 1"
                    : moment(data?.createdAt).format("DD") === "11"
                    ? ", ມື້ທີ່ 2"
                    : moment(data?.createdAt).format("DD") === "12"
                    ? ", ມື້ທີ່ 3"
                    : moment(data?.createdAt).format("DD") === "13"
                    ? ", ມື້ທີ່ 4"
                    : moment(data?.createdAt).format("DD") === "14"
                    ? ", ມື້ທີ່ 5"
                    : ""}
                </span>
              ))}
            </div>
          </div>
        </div>
        <button type="button" className="footer" onClick={handlesubmit}>
          <div>ເຂົ້າຮ່ວມ</div>
        </button>
      </div>
    </>
  );
}
