import React, { useState, useEffect } from "react";
import { Agendaevents } from "../api";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLock,
  faMinus,
  faSearch,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Room } from "../consts/room";

const classList = [
  {
    id: "N5",
    title: "ເວທີກາງ (ຫ້ອງໂຖງ)",
  },
  {
    id: "N1",
    title: "ຫ້ອງປະຊຸມໃຫ່ຍ N1 (1000 ຄົນ)",
  },
  {
    id: "N2",
    title: "ຫ້ອງ N2 (ປະມານ 243-300 ຄົນ)",
  },
  {
    id: "N3",
    title: "ຫ້ອງ N3 (100-200 ຄົນ)",
  },
  {
    id: "N4",
    title: "ຫ້ອງ N4 (100-200 ຄົນ)",
  },
  {
    id: "N14",
    title: "ຫ້ອງ N14 (50-75 ຄົນ)",
  },
  {
    id: "N15",
    title: "ຫ້ອງ N15 (20 ຄົນ)",
  },
  {
    id: "N101",
    title: "ຫ້ອງ N101 (50-75 ຄົນ)",
  },
  {
    id: "N12",
    title: "ຫ້ອງ N12 (20 ຄົນ)",
  },
];

const AgendaList = [
  {
    day: 1,
    date: "ວັນພຸດ 10 ມັງກອນ 2024",
    class: classList,
  },
  {
    day: 2,
    date: "ວັນພະຫັດ 11 ມັງກອນ 2024",
    class: classList,
  },
  {
    day: 3,
    date: "ວັນສຸກ 12 ມັງກອນ 2024",
    class: classList,
  },
  {
    day: 4,
    date: "ວັນສຸກ 13 ມັງກອນ 2024",
    class: classList,
  },
  {
    day: 5,
    date: "ວັນສຸກ 14 ມັງກອນ 2024",
    class: classList,
  },
];

function AgendaAll() {
  const [searchTitle, setSearchTitle] = useState("");
  const [agenda, setAgenda] = useState([]);
  const [agenda2, setAgenda2] = useState([]);

  useEffect(() => {
    axios.get(Agendaevents).then((response) => {
      setAgenda(response.data);
      setAgenda2(response.data);
    });
  }, []);

  useEffect(() => {
    if (searchTitle) {
      const filter = agenda2?.filter((e) => {
        let titlesList = [];

        e?.title.map((res) => {
          titlesList = [...titlesList, res.toLowerCase()];
        });

        titlesList = [...titlesList, e?.time];
        // Search by typing days, class, and title
        if (
          e?.day.toString() === searchTitle ||
          e?.class.toLowerCase().includes(searchTitle.toLowerCase()) ||
          titlesList.find((res) => res.includes(searchTitle.toLowerCase()))
        ) {
          return e;
        }
      });

      setAgenda(filter);
    } else {
      setAgenda(agenda2);
    }
  }, [searchTitle]);

  const handleSearch = (e) => {
    setSearchTitle(e.target.value);
  };

  return (
    <>
      <div className="all-container">
        <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
          ວາລະງານທັງໜົດ
        </h5>
        <div className="boxsearch-all">
          <div style={{ width: "100%", borderRadius: "20px" }}>
            <div className="inputWithIcon mb-3">
              <div className="inner-addon left-addon">
                <FontAwesomeIcon className="glyphicon" icon={faSearch} />
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="ຄົ້ນຫາ..."
                  style={{ borderRadius: "15px", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        {AgendaList?.map((list, index) => {
          return (
            <div
              key={`agenda-list-${index}`}
              className="Agenda-all"
              style={{
                display:
                  agenda?.filter(
                    (e) =>
                      e?.day === list.day &&
                      (e?.class === "N5" ||
                        e?.class === "N1" ||
                        e?.class === "N2" ||
                        e?.class === "N3" ||
                        e?.class === "N4" ||
                        e?.class === "N14" ||
                        e?.class === "N15" ||
                        e?.class === "N101" ||
                        e?.class === "N12")
                  ).length > 0
                    ? "block"
                    : "none",
              }}
            >
              <div style={{ paddingLeft: "20px" }}>
                <h1>{`ມື້ທີ${list.day}`}</h1>
                <p>{list.date}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {list?.class?.map((room, index) => (
                  <React.Fragment key={`room-${room.title}-${index}`}>
                    <div
                      className="box-container-agenda"
                      style={{
                        position: "relative",
                        display:
                          agenda?.filter(
                            (e) => e?.day === list.day && e?.class === room.id
                          ).length > 0
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="head-sgenda-all">
                        {`ສະຖານທີ່ : ${room.title}`}
                      </div>
                      {/* content */}

                      <div className="box-agenda-all">
                        <div style={{ width: "30%", textAlign: "center" }}>
                          ເວລາ
                        </div>
                        <div
                          style={{
                            width: "70%",
                            backgroundColor: "#ffffff",
                            paddingLeft: "20px",
                          }}
                        >
                          ກິດຈະກຳ
                        </div>
                      </div>
                      {agenda
                        ?.filter(
                          (e) => e?.day === list.day && e?.class === room.id
                        )
                        ?.map((e, index) => (
                          <div
                            key={`agenda-filters${index}`}
                            className="box-agenda-content"
                          >
                            <div
                              style={{
                                width: "30%",
                                textAlign: "center",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#133345",
                              }}
                            >
                              {e?.time}
                            </div>
                            <div
                              style={{
                                width: "70%",
                                backgroundColor: "#ffffff",
                                paddingLeft: "20px",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#133345",
                                display: "flex",
                                flexDirection: "column",
                                paddingRight: "24px",
                              }}
                            >
                              {e?.title &&
                                e?.title?.map((titleList, index) => (
                                  <span
                                    key={`titleList-${index}`}
                                    style={{
                                      display: "flex",
                                      gap: "4px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faMinus}
                                      width={8}
                                      color="##0E4065"
                                      style={{
                                        marginTop: ".2rem",
                                      }}
                                    />
                                    {titleList}
                                  </span>
                                ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AgendaAll;
