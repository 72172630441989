import { Routes, Route } from "react-router-dom"
import './App.css';
import Register from './pages/register/Register';
import Home from './pages/home/Home';
import SafeArea from 'react-safe-area-component'
import Detail from "./pages/detail/Detail";
import Qrcode from "./pages/qrcode/Qrcode";

function App() {
  return (
    <>
      <SafeArea top />
      <div className="App">

        <Routes>
          <Route path="Register" element={<Register />} />
          <Route path="/" element={<Home />} />
          <Route path="qrcode" element={<Qrcode />} />
          <Route path="detail/day/:day/title/:title/zone/:zone/class/:class" element={<Detail />} />
        </Routes>

      </div>
      <SafeArea bottom />
    </>

  );
}

export default App;
